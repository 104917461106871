<template>
  <v-container>
    <div class="text-center">
      <h1>Dashboard</h1>
    </div>
    <div class="d-flex pt-5 px-16 justify-center">
      <v-btn class="mx-5 orange-button" to="/new-booking">
        Create new Shipping Instruction
      </v-btn>
      <v-btn class="mx-5 orange-button" to="/allInstructions">
        View my Shipping Instructions
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard'
};
</script>
